import React from "react"
import BackgroundSlider from "gatsby-image-background-slider"
import { useStaticQuery, graphql } from "gatsby"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const buttonStyle = {
  backgroundColor: "#EDC02B",
  borderColor: "#EDC02B",
  borderRadius: "50px",
  fontFamily: "allotrope, sans-serif",
  fontWeight: 700,
  fontSize: "1rem",
  paddingTop: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}

const BackgroundSliderComponent = () => {
  return (
    <div style={{ width: "100%", 
    }}>
      <div
        style={{
          color: "white",
          textAlign: "center",
          backgroundColor: "#3E597F",
          width: "100%",
          padding: "2%",
        }}
      >
        <div style={{}}>
          <h1 style={{ color: "white" }}>
          Sign up now for our Golf Scramble on Monday 05 MAY 2025 at the River Club in Garden City.
          </h1>

          <div style={{marginBottom: '1rem'}}>
            <Link to="/2025-golf-tournament" style={{color: '#EDC02B', fontWeight: 500, fontSize:'1.5rem', marginBottom: '1rem'}}>
              Register Now
            </Link>
          </div>
          <div className="mb-0">
          <h2 style={{fontWeight: 500, fontSize:'1.25rem', marginBottom: 0, color: 'white'}}>
          Sponsorships are available for different events, holes and to sponsor a group of veterans!
          </h2>
          </div>
        </div>
      </div>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "images" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        initDelay={12}
        transition={4}
        duration={10}
        images={["sub-1.jpg", "sub-2.jpg", "sub-3.jpg"]}
        style={{
          overflow: "hidden",
        }}
      />
    </div>
  )
}

export default BackgroundSliderComponent
